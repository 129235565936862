<template>
    <div class="wishlist-product">
        <button @click="removeFromWishList">
            <font-awesome-icon icon="fa-solid fa-trash" class="text-primary" />
        </button>
        <router-link :to="'/' + product.product.url_key">
            <div class="wishlist-image">
                <img :src="product.product.image.url" alt="" v-if="product && product.product && product.product.image">
            </div>
        </router-link>
        <div class="wishlist-meta">
            <router-link :to="'/' + product.product.url_key"><p class="title" :title="product.product.name">{{ productName }}</p></router-link>
            <p class="status" v-if="product.product.stock_status === 'IN_STOCK'">{{ $t('in_stock') }}</p>
            <p class="text-danger" v-else>{{ $t('out_of_stock') }}</p>
            <router-link to="" class="comment_change">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                {{ $t('comment_change') }}</router-link>
        </div>
        <div class="wishlist-action">
            <div class="d-flex flex-wrap justify-content-between">
                <quantity-input :product="product.product" @change-qty="changeQty"></quantity-input>
                <p class="price">{{ formatCurrency(product.product.price_range.maximum_price.final_price.value) }}</p>
            </div>
            <button class="wishlist-cart" @click="addToCart" :disabled="product.product.stock_qty === 0">
                <font-awesome-icon icon="fa-solid fa-shopping-cart" />
                <span class="d-none d-md-block">
                    {{ $t('add_to_cart') }}
                </span>
            </button>
        </div>

        <!-- badges -->
        <!-- <div class="wishlist-badges"> -->
        <!-- <span class="wishlist-label label-out" v-if="product.product.stock_qty === 0">{{ $t('out_of_stock') }}</span> -->
        <!-- <span class="wishlist-label label-top" v-if="product.top">{{ $t('top') }}</span>
            <span class="wishlist-label label-sale" v-if="product.sale_price">{{ $t('sale') }}</span>
            <span class="wishlist-label label-new" v-if="product.new">{{ $t('new') }}</span> -->
        <!-- </div> -->
        <!-- badges -->

        <b-modal id="wishlist-product-delete" class="product-modal-inner wishlist-product-model"
            content-class="defaul-modal" hide-footer centered size="lg">
            <template #modal-title class="bold">
                {{ $t("remove_product_wishlist") }}
            </template>
            <div class="modal-text d-block border-bottom">
                <p>
                    {{ $t("sure_remove_product_wishlist") }}
                </p>
            </div>
            <div class="modal-btn-box pt-20">
                <b-button variant="success" block @click="clickOk()">Ok</b-button>
                <b-button variant="cancel" block @click="clickCancel()">{{ $t("back") }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import QuantityInput from '@/esf_weert_mobilesupplies/core/components/elements/QuantityInput';

export default {
    props: {
        product: Object
    },
    components: {
        QuantityInput,
    },
    data() {
        return {
            qty: 1,
            nameMaxLength: 20
        }
    },
    computed: {
        productName() {
            if (this.product.product.name.length <= this.nameMaxLength) {
                return this.product.product.name;
            } else {
                const words = this.product.product.name.split(' ');
                let shortenedString = '';
                let count = 0;
                for (let i = 0; i < words.length; i++) {
                    if (count + words[i].length <= this.nameMaxLength) {
                        shortenedString += words[i] + ' ';
                        count += words[i].length;
                    } else {
                        break;
                    }
                }
                return shortenedString.trim() + '...';
            }
        }
    },
    methods: {
        changeQty: function (current) {
            this.qty = current;
        },
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        removeFromWishList() {
            this.popupProduct = this.product.product;
            this.$bvModal.show("wishlist-product-delete");
        },
        async clickOk() {
            await this.$store.dispatch("wishlist/removeProductFromWishlist", {
                sku: this.popupProduct.sku,
            });
            this.popupProduct = null;
            this.$bvModal.hide("wishlist-product-delete");
        },
        clickCancel() {
            this.popupProduct = null;
            this.$bvModal.hide("wishlist-product-delete");
        },
        async addToCart() {
            if (this.product.product.__typename == "SimpleProduct") {
                const item =
                    '{data: {sku: "' + this.product.product.sku + '", quantity:' + 1 + "}}  ";
                let retval = await this.$store.dispatch("cart/addToCart", {
                    type: this.product.product.__typename,
                    item: item,
                });

                if (retval == false) {
                    const msg = {
                        type: "danger",
                        title: this.$t("shopping_basket"),
                        text: this.$t("not_add_product"),
                    };
                    this.$store.dispatch("messages/sendMessage", { message: msg });
                } else {
                    const msg = {
                        type: "success",
                        title: this.$t("shopping_basket"),
                        text: this.$t("added_product"),
                    };
                    this.$store.dispatch("messages/sendMessage", { message: msg });
                }
            } else {
                this.$router.push("/" + this.product.product.url_key + "/");
            }
        }
    }
}
</script>