<template>
    <div>
        <h5 class="text-primary">{{ $t('view_order') }}</h5>
        <div v-if="orders.length === 0">
            <p>No order has been made yet.</p>
            <router-link to="/shop" class="btn btn-outline-primary-2">
                <span>GO SHOP</span>
                <i class="icon-long-arrow-right"></i>
            </router-link>
        </div>
        <div v-else>
            <div class="d-none d-md-block">
                <div v-if="orderDetail && showOrderDetail">
                    <h6 class="border-bottom pb-1">
                        <button type="button" @click="viewOrdersList">
                            <font-awesome-icon icon="fa-solid fa-arrow-left" />
                        </button>
                        {{ $t('order_detail') }}
                    </h6>
                    <div class="order-datails" v-for="product in orderDetail.items" :key="product.product_sku">
                        <div class="order-datails-image">
                            <p> <strong>{{ product.order_no }}</strong></p>
                            <img :src="product.image.url" alt="">
                        </div>
                        <div class="order-datails-meta">
                            <b-link :to="'/' + product.product_url_key + '/'" class="title" :title="product.name">{{
            productName }}
                            </b-link>
                            <div>
                                <p class="mb-0">{{ $t('no_of') }}: {{ product.quantity_ordered }}</p>
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="status mb-0" v-if="product.stock_status === 'IN_STOCK'">
                                        {{
            $t('in_stock') }}</p>
                                    <p class="text-danger mb-0" v-else>{{
            $t('out_of_stock')
        }}
                                    </p>
                                    <p class="price mb-0">{{
                formatCurrency(product.product_sale_price_incl_tax.value)
            }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="order-datails-desc">
                            <p> <span>{{ $t('order_date2') }}: {{ convertDate(orderDetail.order_date) }}</span>
                            </p>
                            <p>
                                {{ orderDetail.billing_address.firstname }}
                                {{ orderDetail.billing_address.lastname }}
                            </p>
                            <p>{{ orderDetail.billing_address.street[0] }}</p>
                            <p>{{ orderDetail.billing_address.postcode }},
                                {{ orderDetail.billing_address.city }}</p>
                            <!-- <p>Nederland</p> -->
                            <p>{{ orderDetail.billing_address.telephone }}</p>
                            <p class="sent">{{ $t('sent') }}</p>
                        </div>
                        <div class="order-datails-action">
                            <div class="d-flex flex-column align-items-end">
                                <p class="subtotal"><span> Subtotal:</span> {{
            formatCurrency(orderDetail.total.subtotal.value) }}
                                </p>
                                <p class="shipping-cost">
                                    <span>{{ $t('shipping_costs') }}:</span> {{
            formatCurrency(orderDetail.total.shipping_handling.amount_including_tax.value) }}
                                </p>
                                <span class="underline"></span>
                                <h6>Total: {{ formatCurrency(orderDetail.total.base_grand_total.value) }}</h6>
                            </div>
                            <button class="order-datails-cart">
                                {{ $t('order_again') }}
                            </button>
                        </div>

                        <!-- badges -->
                        <!-- <div class="order-datails-badges">
    <span class="order-datails-label label-out" v-if="product.stock_qty === 0">{{ $t('out_of_stock') }}</span>
    <span class="order-datails-label label-top" v-if="product.top">{{ $t('top') }}</span>
    <span class="order-datails-label label-sale" v-if="product.sale_price">{{ $t('sale') }}</span>
    <span class="order-datails-label label-new" v-if="product.new">{{ $t('new') }}</span>
</div> -->
                        <!-- badges -->
                    </div>
                </div>
                <table class="table table-order" v-else>
                    <thead>
                        <tr>
                            <th>{{ $t('order_number2') }}</th>
                            <th>{{ $t('date') }}</th>
                            <th>{{ $t('send_to') }}</th>
                            <th>{{ $t('total1') }}</th>
                            <th>{{ $t('status') }}</th>
                            <th>{{ $t('action') }}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(order, index) in paginatedData" :key="index">
                            <td class="order_no">{{ order.number }}</td>
                            <td>{{ convertDate(order.order_date) }}</td>
                            <td>{{ order.sent_to }}</td>
                            <td>{{ order.total.base_grand_total.value }}</td>
                            <td class="order_status">
                                <span class="delivered" v-if="order.status === 'Delivered'">
                                    {{ order.status }}
                                </span>
                                <span class="cancelled" v-if="order.status === 'Cancelled'">
                                    {{ order.status }}
                                </span>
                            </td>
                            <td>
                                <button class="btn btn-dark" @click="viewOrder(order.id)">
                                    {{ $t('view_order') }}
                                </button>
                                <button class="btn btn-success ml-2">
                                    {{ $t('order_again') }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- mobile ui -->
            <div class="d-block d-md-none">
                <div v-if="orderDetail && showOrderDetail">
                    <h6 class="border-bottom pb-1"> <button @click="viewOrdersList">
                            <font-awesome-icon icon="fa-solid fa-arrow-left" />
                        </button>
                        {{ $t('order_detail') }}
                    </h6>
                    <div class="order-datails" v-for="product in orderDetail.items" :key="product.product_sku">
                        <div class="order-datails-image">
                            <p> <strong>{{ product.product_sku }}</strong></p>
                            <img :src="product.image.url" alt="">
                        </div>
                        <div class="order-datails-meta">
                            <b-link :to="'/' + product.product_url_key + '/'" class="title"
                                :title="product.product_name">{{
            productName }}
                            </b-link>
                            <div>
                                <p class="mb-0">{{ $t('no_of') }}: {{ product.quantity_ordered }}</p>
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="status" v-if="product.stock_status === 'IN_STOCK'">
                                        {{
            $t('in_stock') }}</p>
                                    <p class="text-danger" v-else>{{
            $t('out_of_stock')
        }}
                                    </p>
                                    <p class="price">€ {{
                formatCurrency(product.product_sale_price_incl_tax.value)
            }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="order-datails-desc">
                            <p> <span>{{ $t('order_date2') }}: {{ convertDate(orderDetail.order_date)
                                    }}</span>
                            </p>
                            <p>{{ orderDetail.shipping_address.firstname + ' ' +
            orderDetail.shipping_address.lastname }}</p>
                            <p>{{ orderDetail.billing_address.street[0] }}</p>
                            <p>{{ orderDetail.billing_address.postcode }},
                                {{ orderDetail.billing_address.city }}</p>
                            <!-- <p>Nederland</p> -->
                            <p>{{ orderDetail.billing_address.telephone }}</p>
                            <p class="sent">{{ $t('sent') }}</p>
                        </div>
                        <div class="order-datails-action">
                            <div class="d-flex flex-column align-items-end">
                                <p class="subtotal"><span> Subtotal:</span>
                                    {{ formatCurrency(orderDetail.total.subtotal.value) }}
                                </p>
                                <p class="shipping-cost">
                                    <span>{{ $t('shipping_costs') }}:</span>
                                    {{ formatCurrency(orderDetail.total.shipping_handling.amount_including_tax.value) }}
                                </p>
                                <span class="underline"></span>
                                <h6>Total: {{ formatCurrency(orderDetail.total.base_grand_total.value) }}</h6>

                            </div>
                            <button class="order-datails-cart">
                                {{ $t('order_again') }}
                            </button>
                        </div>

                        <!-- badges -->
                        <!-- <div class="order-datails-badges">
                <span class="order-datails-label label-out" v-if="product.stock_qty === 0">{{ $t('out_of_stock') }}</span>
                <span class="order-datails-label label-top" v-if="product.top">{{ $t('top') }}</span>
                <span class="order-datails-label label-sale" v-if="product.sale_price">{{ $t('sale') }}</span>
                <span class="order-datails-label label-new" v-if="product.new">{{ $t('new') }}</span>
            </div> -->
                        <!-- badges -->
                    </div>
                </div>
                <table class="table table-order" v-else>
                    <thead>
                        <tr>
                            <th class="text-center">{{ $t('order_number2') }}</th>
                            <th class="text-center">{{ $t('action') }}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(order, index) in paginatedData" :key="index">
                            <td class="order_no text-center">
                                {{ order.number }}
                                <div>{{ convertDate(order.order_date) }}</div>
                                <div>{{ order.sent_to }}</div>
                                <div>{{ order.total.base_grand_total.value }}</div>
                                <div class="order_status">
                                    <span class="delivered" v-if="order.status === 'Delivered'">
                                        {{ order.status }}
                                    </span>
                                    <span class="cancelled" v-if="order.status === 'Cancelled'">
                                        {{ order.status }}
                                    </span>
                                </div>
                            </td>
                            <td class="text-center">
                                <button class="btn btn-dark mb-1" @click="viewOrder(order.id)">
                                    {{ $t('view_order') }}
                                </button>
                                <button class="btn btn-success">
                                    {{ $t('order_again') }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- mobile ui -->
        </div>
        <!-- pagination -->
        <div v-if="orders.length > 0 && !showOrderDetail">
            <pagination-comp :per-page="perPage" :total="totalCount" @handlePerPage="handlePerPage" />
        </div>
        <!-- pagination -->
    </div>
</template>

<script>
// import { orderData } from '@/esf_weert_mobilesupplies/utilities/data';
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";
import PaginationComp from '@/esf_weert_mobilesupplies/core/components/elements/PaginationComp';


export default {
    data() {
        return {
            orderDetail: null,
            showOrderDetail: false, // true
            isOrderDetails: false,
            nameMaxLength: 100,
            // pagination
            perPage: 24,
            totalCount: 0,
            // pagination
        };
    },
    components: {
        PaginationComp,
    },
    mounted() {
        this.$root.$on("switchTab", () => {
            Logger.debug("mounted", "AccountTabOrders", "switchTab")();
            this.hideDetails();
        });
        this.totalCount = this.orders.length;
    },
    watch: {
        activeTab() {
            this.orderDetail = null;
            this.showOrderDetail = false;
        },
        orders(newOrders) {
            this.totalCount = newOrders.length;
        }
    },
    computed: {
        orders() {
            return this.$store.getters["user/getMyOrders"];
        },
        // orders: function () {
        //     return orderData;
        // },
        productName: function () {
            if (this.orderDetail.items[0].product_name.length <= this.nameMaxLength) {
                return this.orderDetail.items[0].product_name;
            } else {
                const words = this.orderDetail.items[0].product_name.split(' ');
                let shortenedString = '';
                let count = 0;
                for (let i = 0; i < words.length; i++) {
                    if (count + words[i].length <= this.nameMaxLength) {
                        shortenedString += words[i] + ' ';
                        count += words[i].length;
                    } else {
                        break;
                    }
                }
                return shortenedString.trim() + '...';
            }
        },
        activeTab() {
            return this.$store.getters["user/getAccountTabIndex"];
        },
        // pagination
        currentPage: function () {
            return parseInt(this.$route.query.page ? this.$route.query.page : 1);
        },
        paginatedData() {
            if (!this.orders) return [];

            let start = (this.currentPage - 1) * this.perPage;
            let end = start + this.perPage;

            // Apply sorting based on sortBy
            let sortedCategories = [...this.orders];
            if (this.sortBy === 'price_low_high') {
                sortedCategories.sort((a, b) => a.price - b.price);
            } else if (this.sortBy === 'price_high_low') {
                sortedCategories.sort((a, b) => b.price - a.price);
            }
            // Add more sorting logic if needed

            return sortedCategories.slice(start, end);
        },
        // pagination
    },
    methods: {
        convertDate(date) {
            let month = [
                this.$t("January"),
                this.$t("February"),
                this.$t("March"),
                this.$t("April"),
                this.$t("May"),
                this.$t("June"),
                this.$t("July"),
                this.$t("August"),
                this.$t("September"),
                this.$t("October"),
                this.$t("November"),
                this.$t("December"),
            ];
            let reviewDate = new Date(date);
            let dd = reviewDate.getDate();
            let mm = month[reviewDate.getMonth()];
            let yyyy = reviewDate.getFullYear();
            reviewDate = dd + " " + mm + " " + yyyy;
            return reviewDate;
        },
        viewOrder(orderId) {
            this.isOrderDetails = !this.isOrderDetails;
            this.orderDetail = this.orders.find((order) => order.id === orderId);
            this.showOrderDetail = true;
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        hideDetails() {
            this.orderDetail = null;
            this.showOrderDetail = false;
        },
        // viewAllOrders() {
        //     this.showOrderDetail = !this.showOrderDetail;
        // },
        viewOrdersList() {
            this.showOrderDetail = false;
        },
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount, false);
        },
        requestReturn() {
            this.$router.push({ path: '/account/', query: { name: 'returns', new: this.orderDetail.number } });
        },
        // pagination
        handlePerPage: function (count) {
            this.perPage = count;
        }
        // pagination
    },
    metaInfo() {
        return {
            title: this.$t("order"),
            link: [
                {
                    rel: "canonical",
                    href:
                        config.app_url +
                        (/\/$/.test(this.$route.fullPath)
                            ? this.$route.fullPath
                            : this.$route.fullPath.replace(/^\//, "") + "/"),
                },
            ],
        };
    },
};
</script>