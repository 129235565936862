<template>
    <main class="main">
        <nav class="breadcrumb-nav">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Home</router-link>
                    </li>
                    <!-- <li class="breadcrumb-item">
                        <router-link to="/shop/sidebar/list">Shop</router-link>
                    </li> -->
                    <li class="breadcrumb-item active">{{ $t('my_account') }}</li>
                </ol>
            </div>
        </nav>

        <div class="page-content">
            <div class="dashboard my-account">
                <div class="container">
                    <div class="row">
                        <aside class="col-md-4 col-lg-3">
                            <tabs class="nav-dashboard flex-column mb-3 mb-md-0" :data="tabsData"></tabs>
                        </aside>

                        <div class="col-md-8 col-lg-9">
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="ordertab">
                                    <orders />
                                </div>

                                <div class="tab-pane fade" id="wishlistTab">
                                    <wishlist />
                                </div>

                                <div class="tab-pane fade" id="directoryTab">
                                    <address-book />
                                </div>

                                <div class="tab-pane fade" id="mydetailsTab">
                                    <my-details />
                                </div>

                                <div class="tab-pane fade" id="myProductReviewsTab">
                                    <product-reviews />
                                </div>

                                <div class="tab-pane fade" id="newsletterSubscriptionsTab">
                                    <newsletter />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import Tabs from '@/esf_weert_mobilesupplies/core/components/elements/TabsDefault.vue';
import Orders from '@/esf_weert_mobilesupplies/core/components/partial/Account/Orders.vue';
import Wishlist from '@/esf_weert_mobilesupplies/core/components/partial/Account/Wishlist.vue';
import MyDetails from '@/esf_weert_mobilesupplies/core/components/partial/Account/MyDetails.vue';
import ProductReviews from '@/esf_weert_mobilesupplies/core/components/partial/Account/ProductReviews.vue';
import Newsletter from '@/esf_weert_mobilesupplies/core/components/partial/Account/Newsletter.vue';
import AddressBook from '@/esf_weert_mobilesupplies/core/components/partial/Account/AddressBook.vue';

export default {
    components: {
        Tabs,
        Orders,
        Wishlist,
        ProductReviews,
        AddressBook,
        MyDetails,
        Newsletter
    },
    data: function () {
        return {
            tabsData: [
                {
                    id: 'ordertab',
                    title: 'view_order',
                    active: true
                },
                {
                    id: 'wishlistTab',
                    title: 'wishlist',
                    active: false
                },
                {
                    id: 'directoryTab',
                    title: 'directory',
                    active: false
                },
                {
                    id: 'mydetailsTab',
                    title: 'my_details',
                    active: false
                },
                {
                    id: 'myProductReviewsTab',
                    title: 'my_product_reviews',
                    active: false
                },
                {
                    id: 'newsletterSubscriptionsTab',
                    title: 'newsletter_subscriptions2',
                    active: false
                },
                {
                    id: 'tab8',
                    title: 'log_out',
                    active: false
                }
            ],
        };
    },
    // watch: {
    //     $route: function () {
    //         if (this.$route.hash != "") {
    //             this.tabsData = this.tabsData.map((tab) => {
    //                 if (this.$route.hash == '#' + tab.id) {
    //                     return { ...tab, active: true };
    //                 } else {
    //                     return { ...tab, active: false };
    //                 }
    //             });
    //         }
    //     }
    // },
    // created: function () {
    //     if (this.$route.hash != "") {
    //         this.tabsData = this.tabsData.map((tab) => {
    //             if (this.$route.hash == '#' + tab.id) {
    //                 return { ...tab, active: true };
    //             } else {
    //                 return { ...tab, active: false };
    //             }
    //         });
    //     }
    // },
    // methods: {
    //     toOrder: function () {
    //         document
    //             .querySelector('.nav-dashboard .nav-item:nth-child(2) a')
    //             .click();
    //     },
    //     toAddress: function () {
    //         document
    //             .querySelector('.nav-dashboard .nav-item:nth-child(4) a')
    //             .click();
    //     },
    //     toAccount: function () {
    //         document
    //             .querySelector('.nav-dashboard .nav-item:nth-child(5) a')
    //             .click();
    //     }
    // }
};
</script>