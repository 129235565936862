<template>
    <div class="review-details">
        <div class="review-image">
            <img :src="product.product.image.url" width="200" alt="">
        </div>
        <div class="review-meta">
            <p class="title" :title="product.name">{{ productName }}</p>
            <p><strong>Kwaliteit</strong> <font-awesome-icon
                    v-for="(_, index) in Array.from({ length: product.average_rating }).fill('')" :key="index"
                    icon="fa-solid fa-star" class="text-primary" /></p>
            <p><strong>Prijs</strong> <font-awesome-icon
                    v-for="(_, index) in Array.from({ length: product.average_rating }).fill('')" :key="index"
                    icon="fa-solid fa-star" class="text-primary" /></p>
            <p>Gereviewd door <strong>Jan</strong> (customer@gmail.com) op 14-01-23</p>
            <p>{{ product.summary }}</p>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        product: Object
    },
    data() {
        return {
            qty: 1,
            nameMaxLength: 20
        }
    },
    computed: {
        productName() {
            if (this.product.product.name.length <= this.nameMaxLength) {
                return this.product.product.name;
            } else {
                const words = this.product.product.name.split(' ');
                let shortenedString = '';
                let count = 0;
                for (let i = 0; i < words.length; i++) {
                    if (count + words[i].length <= this.nameMaxLength) {
                        shortenedString += words[i] + ' ';
                        count += words[i].length;
                    } else {
                        break;
                    }
                }
                return shortenedString.trim() + '...';
            }
        }
    },
    methods: {
        changeQty: function (current) {
            this.qty = current;
        },
    }
}
</script>